import React from 'react'
import styled from 'styled-components'

import { H1, H2, H3, P } from '../../util/standard'

const Wrapper = styled.div`
width: 80%;
margin: 50px auto 150px auto;`

const StyledH2 = styled(H2)`
margin-bottom: 20px;
`

const StyledP = styled(P)`
margin-bottom: 40px;`

function PolicyContent() {
  return (
    <Wrapper>
      <H1 fontWeight='bold'>Policies</H1>
      <StyledH2>Use of the Warriors Community Foundation Website</StyledH2>
      <StyledP>You may browse and access information contained within the website without providing any personal information.
      Where you voluntarily provide personal information (eg. through submitting an online form, subscribing to a newsletter, or signing up as a member), we will only use that information to communicate with you. We will keep any such information secure and will not disclose it to any third parties.
      </StyledP>
      <StyledH2>Information Gathering Model Standards</StyledH2>
      <StyledP>In addition, both the use and security of personal information gathered by the Warriors Community Foundation in respect of any of our functions or activities is managed in accordance with our information management policy and in compliance with the Privacy Act 1993.</StyledP>
      <StyledH2>Cookies</StyledH2>
      <StyledP>
        This website uses cookies, which are small text files (often including a unique identifier), that are sent to your browser from a website's computers and stored on your computer's hard drive or on your tablet or mobile device. Most websites you visit will use cookies in order to improve your user experience by enabling that website to “remember” you, either for the duration of your visit (using a “session cookie”) or for repeat visits (using a “persistent cookie”).
        You can delete cookies by changing your privacy preferences in your internet browser. You also have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer. Please note that some parts of the website will not function correctly if you disable cookies.
      </StyledP>
      <H3 color="black">Our Website primarily uses the following four types of cookies:</H3>
      <ul>
        <li><P>Operationally Necessary Cookies</P>
          <StyledP>
            These are cookies that are essential for the functioning of the site or to perform tasks requested by the user. For example, cookies that enable you to access secure areas of the website. Simply put, they make it possible for you to browse and use our Website.
          </StyledP>
        </li>
        <li><P>Performance Cookies</P>
          <StyledP>
            These cookies gather information about how visitors use the Website and monitor Website performance. For example, performance cookies tell us which pages are popular, monitor traffic to our Website and compile anonymous analytic information. Performance cookies can also be used to identify and remedy operational problems with the Website.
          </StyledP>
        </li>
      </ul>

      <StyledH2>Are there cookies in emails?</StyledH2>
      <StyledP>
        Our emails contain cookies that track whether you open our emails and whether you click any links within them. The cookies used in our emails to monitor these behaviours are unique to each email and are not stored on your computer or mobile device.
      </StyledP>
      <StyledH2>Enquiries and complaints</StyledH2>
      <StyledP>If you have any enquiries about our information gathering activities, wish to view any of your personal information held by us and/or correct that information, or if you have any concerns or wish to lodge a complaint with the Warriors Community Foundation about our information gathering practices, please email (EMAIL ADDRESS).</StyledP>
    </Wrapper>
  )
}

export default PolicyContent
