import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Hero from '../components/shared/Hero'
import PolicyContent from '../components/policies/PolicyContent'
import SEO from '../components/shared/Seo'


function policies() {

  const { sanityPolicies } = useStaticQuery(graphql`
  {
    sanityPolicies {
      policyHero {
        heroImage {
          asset {
            fluid {
              srcWebp
              srcSetWebp
              srcSet
              src
              ...GatsbySanityImageFluid
            }
            title
          }
        }
        heroText
      }
         seoBlock {
          pageDescription
          pageKeyWords  
          pageTitle
          ogImage {
            asset {
              url
            }
          }
        }
    }
  }
  `)

  const { policyHero } = sanityPolicies

  return (
    <>
      <SEO seoData={sanityPolicies?.seoBlock} slug='policies' />
      <Hero heroText={policyHero.heroText} heroImage={policyHero.heroImage} />
      <PolicyContent />
    </>
  )
}

export default policies
